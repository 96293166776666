<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col cols="4" offset="6">
                <text-field v-model="filters.userName" label="username"/>
            </v-col>
            <v-col cols="2">
                <v-btn class="btn-accent" block text @click="handleFilter">filter</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.user="{ item }">
                {{ item.user.slug }}
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import BankTransfer from '../../../models/BankTransfer';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import moment from "moment";
import TextField from "@/components/app/form/TextField.vue";
import WithdrawalBankTransfer from "@/models/admin/WithdrawalBankTransfer";

export default {
    name: "bank-transfers-index",
    components: {TextField, AdminDashboardLayout},
    data: function () {
        return {
            loading: false,
            items: [],
            filters: {
                userName: '',
            },
            headers: [
                {text: 'User', value: 'user'},
                {text: 'Bank name', value: 'bank_name'},
                {text: 'IBAN', value: 'iban_code'},
                {text: 'Bank country', value: 'bank_country'},
                {text: 'Your city', value: 'your_city'},
                {text: 'Created time', value: 'created_at'},
            ],
        }
    },
    methods: {
        handleClickRow(items) {
            this.$router.push({name: 'admin.bank-transfers.show', params: {id: items.id}})
        },
        handleFilter() {
            this.fetchBankTransfer()
        },
        async fetchBankTransfer() {
            this.loading = true
            let qb = BankTransfer
            if (this.filters.userName) {
                qb = qb.where('user-name', this.filters.userName)
            }
            this.items = await qb.get()
            this.items.map((value) => {
                value.created_at = moment(value.created_at).format('lll')
            })
            this.loading = false
        }
    },
    created() {
        this.fetchBankTransfer()
    },
}
</script>

<style scoped>
.container {
    max-width: inherit !important;
}
</style>
